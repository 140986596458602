import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userInfo: any;

  constructor(public afAuth: AngularFireAuth, private _httpClient: HttpClient) { }

  async  loginWithGoogle() {
    try {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      var result = await this.afAuth.signInWithPopup(provider);
      this.userInfo = result;
      window.localStorage.setItem('userinfo', JSON.stringify(this.userInfo.user));
      console.log(result)
      return result;
    } catch (error) {
      console.log('error=========', error)
      return error;
    }
  }

  async logout() {
    await this.afAuth.signOut();
    window.localStorage.removeItem('userinfo');
  }

  isUserLoggedInAlready() {
    this.afAuth.authState.subscribe(user => {
      console.log(user)
    })
  }

  getUserData() {
    return this.userInfo;
  }

  verifyGlober() {
    let url = environment.apiPath;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': this.userInfo.credential.idToken, 'authorization': `Bearer ${accessToken}` });
    return this._httpClient.get(url, { headers: headers }).toPromise();
  }

  fetchAppList() {
    let url = `${environment.baseUrl}/home/apps`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` });
    return this._httpClient.get(url, { headers: headers }).toPromise();
  }

  fetchAppDetails(appId) {
    let url = `${environment.baseUrl}/home/apps/${appId}/details`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` });
    return this._httpClient.get(url, { headers: headers }).toPromise();
  }


  refreshTokenGenerater() {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken(true).then((token) => {
            let storage = JSON.parse(window.localStorage.getItem('userinfo'));
            storage['stsTokenManager']['accessToken'] = token;
            window.localStorage.setItem('userinfo', JSON.stringify(storage));
            resolve();
          }).catch((error) => {
            reject();
          });
        } else {
        }
      });
    })
  }

  downloadApp() {
    // let url = "https://us-central1-globant-app-store-dev.cloudfunctions.net/api_dev_v1_0_0/home/apps/details/download";
    // let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` });
    // this._httpClient.get(url, { headers: headers }).toPromise()
    //   .then((resp: any) => { 
    //     console.log(resp)
    //   })
  }

}
