<div>
    <div class="detail-wrapper" [ngClass]="{'restrict-height' : showImageCarousel === true || errorOnDownload ===true}">
        <div class="img-carousel-container" [ngStyle]="{'display':showImageCarousel === true ? 'block' : 'none' }">
            <div class="img-carousel" (click)="showImageCarousel = false">
                <div>
                    <img src="../../assets/images/iPhone XR-XS Max-11 – 2.png" alt="back_btn" />
                </div>
                <div>
                    <img src="../../assets/images/iPhone XR-XS Max-11 – 3.png" alt="back_btn" />
                </div>
                <div>
                    <img src="../../assets/images/iPhone XR-XS Max-11 – 4.png" alt="back_btn" />
                </div>
                <div>
                    <img src="../../assets/images/iPhone XR-XS Max-11 – 5.png" alt="back_btn" />
                </div>
                <div>
                    <img src="../../assets/images/iPhone XR-XS Max-11 – 6.png" alt="back_btn" />
                </div>
                <!-- <div>
                    <img src="../../assets/images/6 Android.png" alt="back_btn" />
                </div>
                <div>
                    <img src="../../assets/images/7 Android.png" alt="back_btn" />
                </div>
                <div>
                    <img src="../../assets/images/8 Android.png" alt="back_btn" />
                </div> -->
            </div>
        </div>
        <div class="error-page-container" [ngStyle]="{'display':errorOnDownload === true ? 'block' : 'none' }">
            <div class="error-wrapper">
                <div class="error-body">
                    <div class="robot-container">
                        <img height="159px" width="211px" src="../../assets/images/Aliennew@2x.png" alt="back_btn" />
                    </div>
                    <div class="rectangle-hand">
                        <img height="49px" width="36px" src="../../assets/images/Rectangle 3366@2x.png"
                            alt="back_btn" />
                    </div>
                    <div class="blue-background">
                        <img src="../../assets/images/bummer back.png" alt="back_btn" />
                    </div>
                    <div class="error-msg-container">
                        <p class="bummer-text">Let me in!</p>
                        <span>Your session expired, please <br>log in again to use the app. </span>
                    </div>
                    <div class="error-footer">
                        <div class="error-leaf-left">
                            <img src="../../assets/images/Group 6817@2x.png" alt="footer" />
                        </div>
                        <img src="../../assets/images/footer.png" alt="footer" />
                        <div class="error-leaf-right">
                            <img src="../../assets/images/Group 6816@2x.png" alt="footer" />
                        </div>
                    </div>
                </div>
                <div class="cta">
                    <div class="cat-btn" (click)="sessionExpired()">
                        <span>Take me to Login</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail-header">
            <div class="back-btn">
                <div class="back-btn-arrow" (click)="backToHomeScreen()">
                    <img src="../../assets/images/Icon material-arrow_left.png" alt="back_btn" />
                </div>
                <!-- <div class="search-glass">
                    <img src="../../assets/images/icon_search.png" alt="back_btn" />
                </div> -->
            </div>
            <div class="app-detail">
                <div class="recent-app-logo">
                    <img src="../../assets/images/Group 6812@2x.png" alt="globant_expenses" />
                </div>
                <div class="recent-app-details">
                    <span class="recent-app-details-name">Globant Expenses</span>
                    <span class="recent-app-details-size">Updated on Sept 28, 2020</span>
                    <span class="recent-app-details-size">Version 2.1.0</span>
                </div>
            </div>
            <div class="install-btn-container">
                <a [href]="downloadLink"><span>Install App</span></a>
            </div>
            <div class="app-video-container">
                <video width="92%" height="190" poster="../../assets/images/Feature Graphics Play Store Expenses.png"
                    controls playsinline>
                    <source src="../../assets/images/Globant Expenses.MP4" type='video/mp4'>
                </video>
            </div>
            <div class="btn-carousel-container">
                <div class="btn-carousel" (click)="showImageCarousel = true">
                    <div>
                        <img src="../../assets/images/iPhone XR-XS Max-11 – 2.png" alt="back_btn" />
                    </div>
                    <div>
                        <img src="../../assets/images/iPhone XR-XS Max-11 – 3.png" alt="back_btn" />
                    </div>
                    <div>
                        <img src="../../assets/images/iPhone XR-XS Max-11 – 4.png" alt="back_btn" />
                    </div>
                    <div>
                        <img src="../../assets/images/iPhone XR-XS Max-11 – 5.png" alt="back_btn" />
                    </div>
                    <div>
                        <img src="../../assets/images/iPhone XR-XS Max-11 – 6.png" alt="back_btn" />
                    </div>
                    <!-- <div>
                        <img src="../../assets/images/6 Android.png" alt="back_btn" />
                    </div>
                    <div>
                        <img src="../../assets/images/7 Android.png" alt="back_btn" />
                    </div>
                    <div>
                        <img src="../../assets/images/8 Android.png" alt="back_btn" />
                    </div> -->
                </div>
            </div>
        </div>
        <div class="body">
            <!-- <div class="whats-new-section">
                <p> What's new </p>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
            </div> -->
            <div class="about-app-section">
                <p> About this App </p>
                <p>New Feature for Cash Expenses!<br>
                    Now when you try load a new receipt for a cash expense, the app will use the OCR technology to
                    recognise some of the attributes needed for the expense to facilitate the expense reporting<br>
                    ● Take a picture! Or upload it, and the application will read the ticket to identify the most
                    important attributes for the expense
                    <br>Notifications<br>
                    Now you will be receiving notifications about the progress of your reports! You will be updated if
                    the report was approved and when it is ready to be paid
                </p>
            </div>
            <hr>
            <div class="other-app-section">
                <div class="forward-btn">
                    <div class="other-app-title">
                        <p>Check our other apps</p>
                    </div>
                    <div class="forward-btn-arrow" (click)="scrollRight()">
                        <img src="../../assets/images/Icon material-arrow_right.png" alt="back_btn" />
                    </div>
                </div>
                <div>
                    <div #widgetsContent class="other-app-carousel">
                        <div *ngFor="let app of otherAppCarouselData">
                            <a (click)="navigateToApps(app)"><img width="99px" height="99px" [src]='app.logo_url'
                                    alt="better_me" /></a>
                            <span class="app-name">{{app.name}}</span>
                            <span class="app-size" *ngIf="!isAndroid">{{app.size}}</span>
                            <span class="app-size" *ngIf="isAndroid">{{app.size_android}}</span>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
        </div>
        <div class="details-footer">
            <div class="app-info">
                <p> Information </p>
                <div class="info-section">
                    <div><span>Version</span><span>2.1.0</span></div>
                    <div><span>Update</span><span>Sept 28, 2020</span> </div>
                    <div><span>Size</span> <span>23.5 MB</span></div>
                    <div><span>Languages</span><span>English</span></div>
                    <div><span>Launched on</span><span>Mar 01, 2017</span></div>
                </div>
            </div>
            <div class="footer-background">

            </div>
        </div>
    </div>
</div>