import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from "@angular/fire/auth";
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isGlobantUser: boolean = true;
  isLoading = false;
  isMobile = false;
  qrcodepath: string = environment.qrCodePath
  @ViewChild('errorMsg', { static: true }) errorMsg: ElementRef;

  constructor(private loginService: LoginService, private router: Router,
    public afAuth: AngularFireAuth, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isMobile = /iPhone|Android/i.test(navigator.userAgent);
    //this.isMobile = true;
    if (this.isMobile) {
      window.scrollTo(0, 50)
    } else {
      this.router.navigate(['/desktop']);
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let data;
    if (navigator.cookieEnabled)
      data = window.localStorage.getItem('userinfo');
    if (data) {
      this.route.queryParams.subscribe((params) => {
        let appId = params['appId']
        if (appId) {
          this.router.navigate(['/appDetails', appId], { queryParams: { action: 'download' } });
        } else {
          this.router.navigate(['/home']);
        }
      })
    }
  }

  async loginWithGoogle() {
    this.isLoading = true;
    this.isGlobantUser = true;
    let userData = await this.loginService.loginWithGoogle();
    if (userData instanceof Error) {
      this.isGlobantUser = false;
      this.loginService.logout();
      this.isLoading = false;
      if (userData.message == environment.cookieErrorMsg) {
        this.errorMsg.nativeElement.innerHTML = "Please enable cookies in your browser setting";
      } else if (!navigator.cookieEnabled) {
        this.errorMsg.nativeElement.innerHTML = "Please enable cookies in your browser setting";
      } else {
        this.errorMsg.nativeElement.innerHTML = "something went wrong please refresh and try again";
      }
    }
    else if (userData && userData.user.email.includes('globant.com')) {
      try {
        await this.loginService.verifyGlober();
        this.route.queryParams.subscribe((params) => {
          let appId = params['appId']
          if (appId) {
            this.router.navigate(['/appDetails', appId], { queryParams: { action: 'download' } });
          } else {
            this.router.navigate(['/home']);
          }
        })
        this.isGlobantUser = true;
        this.isLoading = false;
      } catch (error) {
        console.log(error)
      }
    }
    else {
      this.isGlobantUser = false;
      this.isLoading = false;
      this.loginService.logout();
    }
  }

  logout() {
    this.loginService.logout();
  }

}
