<div>
    <div class="wrapper" [ngStyle]="{'display':isMobile === true ? 'block' : 'none' }">
        <div class = "progress-bar" *ngIf = "isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="background">
            <div class="app-logo">
                <img width="306px" height="306px" src="../../assets/images/App logo@2x.png" alt="app_background" />
            </div>
        </div>
        <div class="welcome">
            <p class="welcome-text" [ngStyle]="{'display':isGlobantUser === true ? 'block' : 'none' }">Welcome!</p>
            <p #errorMsg class="login-error-msg" [ngStyle]="{'display':isGlobantUser === false ? 'block' : 'none' }">You
                must login with a <span>Globant</span> email account
            </p>
        </div>
        <div class="login-btn" (click)="loginWithGoogle()">
            <!-- <a><span>G Login with Google</span></a> -->
            <img width="288px" height="86px" src="../../assets/images/G signin@2x.png" alt="Google_sign_in" />
        </div>
        <div class="globant-logo">
            <img width="109px" height="22px" src="../../assets/images/globant-logo-dark@2x.png" alt="globant_dark_logo" />
        </div>
    </div>
</div>