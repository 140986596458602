import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { message } from '../constant/message';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  showLogoutScreen: boolean = false;
  userData: any;
  appData: any;
  currentApp: any;
  isAndroid: any = false;
  isLoading: boolean = false;

  constructor(private loginService: LoginService,
    private router: Router, ) {
    this.isAndroid = /android/i.test(navigator.userAgent);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userData = JSON.parse(window.localStorage.getItem('userinfo'));
    window.scrollTo(0, 0);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.getAppList();
  }

  getAppList() {
    this.loginService.fetchAppList().then(async (data: any) => {
      if (data.errorCode == 0) {
        this.appData = data.data.apps;
        this.currentApp = this.appData.find((app) => app.isFeatured);
        this.isLoading = false;
      } else if (data.errorCode == 4013) {
        await this.loginService.refreshTokenGenerater();
        this.getAppList();
      } else {
        this.isLoading = false;
        this.logout();
      }
    }).catch((err) => { this.isLoading = false; });
  }

  logout() {
    this.loginService.logout();
    this.showLogoutScreen = false;
    window.localStorage.removeItem('userinfo');
    this.router.navigate(['/login'])
  }

  navigateToAppDetail(app) {
    if (this.isAndroid) {
      window.location.href = message.GLOBANT_ANDROID_APPS_URL;
    } else {
      if (app.releaseMode == 'INTERNAL')
        this.router.navigate(['/appDetails', app.id]);
      else {
        window.location.href = app.appStoreLink;
      }
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
