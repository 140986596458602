<div>
    <div class="desktop-wrapper" [ngStyle]="{'min-width': minWidth}">
        <div class="desktop-left">
            <img height="100%" width="100%" src="../../assets/images/desktop-back-new.png" alt="background_img" />
        </div>
        <div class="desktop-right">
            <div class="right-leaf">
                <img src="../../assets/images/Group 6801.png" alt="left_side_leaf" />
            </div>
            <div class="welcome-to-globant-title">
                <span>Welcome to Globant's</span>
            </div>
            <div class="app-title">
                <span>APP</span>
            </div>
            <div class="app-market">
                <span>MARKET</span><span class="green-circle"></span>
            </div>
            <div class="app-description-container">
                <div class="vertical-seprater">

                </div>
                <div class="app-description">
                    <p>
                        <span>Our Globant App family is quickly growing!</span> <br>
                        We’ve gathered them in one place to make it <br>
                        easier to find the ones that could help improve <br>
                        your experience.<br><br>
                        Use the <span>QR code</span> to access from your mobile <br>
                        device and download the apps from the App<br>
                        Store or Google Play.
                    </p>
                </div>
            </div>
            <div class="qr-code-container">
                <img [src]="qrcodepath" alt="left_side_leaf" />
                <img width="109px" height="22px" src="../../assets/images/globant-logo-dark@2x.png" alt="globant_dark_logo" />
            </div>
        </div>
    </div>
</div>
