import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login.desktop',
  templateUrl: './login.desktop.component.html',
  styleUrls: ['./login.desktop.component.css']
})
export class LoginDesktopComponent implements OnInit {
  qrcodepath: string = environment.qrCodePath;
  isMobile = false;
  minWidth : any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (this.isMobile) {
      this.router.navigate(['/login']);
    }else{
      this.minWidth = window.innerWidth + "px";
    }
  }

}
