<div>
    <div class="home-wrapper" [ngClass]="{'restrict-height' : showLogoutScreen === true}">
        <div class="logout-container" [ngStyle]="{'display':showLogoutScreen === true ? '' : 'none' }"
            (click)="showLogoutScreen = false">
            <div class="logout-card">
                <div class="logout-card-content">
                    <div class="user-info">
                        <div class="user-info-dp">
                            <img height="53px" width="53px" [src]='userData?.photoURL'>
                        </div>
                        <div class="user-info-email">
                            <span class="recent-app-details-name">{{userData?.displayName}}</span>
                            <span class="user-email-id">{{userData?.email}}</span>
                        </div>
                    </div>
                    <div class="logout-btn" (click)="logout()">
                        <span>LOG OUT</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="progress-bar" *ngIf="isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class='header'>
            <div class="app-title">
                <span>App Market</span>
                <span class="green-dot"></span>
            </div>
            <div class="bell-icon">
                <!-- <img src="../../assets/images/bell noti.png"> -->
            </div>
            <div class="profile" (click)="showLogoutScreen = true">
                <img height="34px" width="34px" [src]='userData?.photoURL'>
            </div>
        </div>
        <div class="content-container">
            <!-- <div class="search">
                <div class="search-background">
                    <input type="text" placeholder="Search for an app...">
                </div>
            </div> -->
            <div class="recent-app" *ngIf="currentApp">
                <div class="recent-app-banner">
                    <img [src]="currentApp?.banner?.path" alt="recent_app_banner" />
                </div>
                <div class="recent-app-link">
                    <div class="recent-app-logo" (click)="navigateToAppDetail(currentApp)">
                        <img [src]="currentApp?.logo" width="60px" height="60px" alt="recent_app_banner" />
                    </div>
                    <div class="recent-app-details">
                        <span class="recent-app-details-name">{{currentApp?.name}}</span>
                        <span class="recent-app-details-size">{{formatBytes(currentApp?.size ,2)}}</span>
                    </div>
                </div>
            </div>
            <div class="app-collection">
                <div *ngFor="let app of appData">
                    <a (click)="navigateToAppDetail(app)"><img width="99px" height="99px" [src]='app.logo'
                            alt="app.name" /></a>
                    <span class="app-name">{{app.name}}</span>
                    <!-- <span class="app-size" *ngIf="!isAndroid">{{formatBytes(app.size,2)}}</span> -->
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="globant-logo">
                <img width="109px" height="22px" src="../../assets/images/globant-logo-dark@2x.png"
                    alt="globant_dark_logo" />
            </div>
            <div class="social-handles">
                <a href="https://www.facebook.com/Globant"><img src="../../assets/images/Facebook@2x.png"
                        alt="facebook" /></a>
                <a href="https://twitter.com/globant"><img src="../../assets/images/Twitter@2x.png" alt="twitter" /></a>
                <a href="https://www.youtube.com/user/Globant"><img src="../../assets/images/Youtube@2x.png"
                        alt="youtube" /></a>
                <a href="https://www.linkedin.com/company/globant"><img src="../../assets/images/LinkedIn@2x.png"
                        alt="linkedin" /></a>
                <a href="https://www.instagram.com/globantpics"><img src="../../assets/images/Instagram@2x.png"
                        alt="instagram" /></a>
                <a href="https://www.globant.com"><img src="../../assets/images/globant-vector-logo@2x.png"
                        alt="globant" /></a>
            </div>
            <div class="copyright">
                <span>All rights reserved Globant 2020.</span>
            </div>
            <div class="footer-background">

            </div>
        </div>
    </div>
</div>