import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { message } from '../constant/message';

@Component({
  selector: 'app-expense-app',
  templateUrl: './expense-app.component.html',
  styleUrls: ['./expense-app.component.css']
})
export class ExpenseAppComponent implements OnInit {

  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  showImageCarousel: boolean = false;
  errorOnDownload: boolean = false;
  downloadLink: any;
  isAndroid: any = false;
  constructor(private router: Router, private loginService: LoginService, private sanitizer: DomSanitizer) {
    this.isAndroid = /android/i.test(navigator.userAgent);
  }

  otherAppCarouselData = message.appList;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.downloadLink = this.sanitizer.bypassSecurityTrustUrl(environment.expenseDownloadLink);
  }

  backToHomeScreen() {
    this.router.navigate(['/home']);
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollLeft += 150;
  }

  downlaodApp() {
    this.loginService.verifyGlober().then((response: any) => {
      if (response.statusCode == 200) {
        var aTag = document.createElement('a');
        aTag.setAttribute('href', environment.downloadLink);
        aTag.innerText = "link text";
        aTag.click();
        this.errorOnDownload = false;
      } else {
        this.errorOnDownload = true;
      }
    })
      .catch((err) => {
        this.errorOnDownload = true;
      })

  }

  navigateToApps(app) {
    if (/android/i.test(navigator.userAgent)) {
      window.location.href = app.google_playstore_url;
    } else {
      if (app.name == 'PODsVIEW')
        this.router.navigate(['/appDetails']);
      else if (app.name == 'Globant Expenses')
        this.router.navigate(['/expense']);
      else {
        window.location.href = app.app_url;
      }
    }
  }

  sessionExpired() {
    this.errorOnDownload = false;
    this.loginService.logout();
    window.localStorage.removeItem('userinfo');
    this.router.navigate(['/login'])
  }
}
