export const message = {
    appList: [
        {
            'logo': '../../assets/images/Mask Group 8234@2x 9.png',
            'name': 'BetterMe',
            'size': '27.9 MB',
            'size_android': '17 MB',
            'app_url': 'https://apps.apple.com/us/app/betterme-by-starmeup-os/id1047237551',
            'google_playstore_url': 'https://play.google.com/store/apps/details?id=com.globant.sop.starmeupfeedback&hl=en_IN'
        },
        {
            'logo': '../../assets/images/Group 6812@2x.png',
            'name': 'Globant Expenses',
            'size': '23.5 MB',
            'size_android': '47 MB',
            'app_url': 'https://apps.apple.com/us/app/globant-expenditures/id1123039376',
            'google_playstore_url': 'https://play.google.com/store/apps/details?id=com.globant.expenditures&hl=en_IN'
        }, {
            'logo': '../../assets/images/Mask Group 8234@2x 7.png',
            'name': 'PODsVIEW',
            'size': '28 MB',
            'size_android': '5.2 MB',
            'app_url': '/appDetails',
            'google_playstore_url': 'https://play.google.com/store/apps/details?id=com.globant.globantview&hl=en_IN'
        },
        {
            'logo': '../../assets/images/Mask Group 8234@2x 8.png',
            'name': 'StarMeUp',
            'size': '52.8 MB',
            'size_android': '12 MB',
            'app_url': 'https://apps.apple.com/us/app/starmeup/id973506772',
            'google_playstore_url': 'https://play.google.com/store/apps/details?id=com.globant.starmeup&hl=en_IN'
        },
    ],
    GLOBANT_ANDROID_APPS_URL: "https://play.google.com/store/apps/developer?id=Globant+Mobile+Studio&hl=en_US",
    DEFAULT_INSTRUCTIONS: "A user needs to make sure that they trust the application certificate on their device from the following path: 'Settings' App > 'General' > 'Profiles & Device Management' > 'Globant, LLC' > 'Trust 'Globant, LLC'."
}